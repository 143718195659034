<template>
  <div class="container">
    <nav id="navigation" class="header-nav">
      <ul style="font-weight: bold">
        <li><a href="#top">Top</a></li>
        <li><a href="#contentCertificate">Certificate</a></li>
        <li><a href="#contentComplaints">Complaints</a></li>
        <li><a href="#contentSelfExclusion">Self-Exclusion</a></li>
      </ul>
    </nav>
    <div class="wrapper">
      <!-- Validation -->
      <div id="contentValid" class="content content-type-1">
        <div class="row logo-content">
          <div class="col-md-4">
            <div class="images-box">
              <img src="~@/assets/images/logo.png" id="img1" />
            </div>
          </div>
          <div class="col-md-8">
            <div id="Div3" class="text-2">
              <h2>SAINT VINCENT EGAMING <span>LICENSE VERIFICATION</span></h2>
            </div>
          </div>
        </div>

        <ul class="c-table hebele">
          <li>
            <div class="title">Company name</div>
            <div id="companyName" class="text">EggsBook Unichain Solutions LLC</div>
          </li>
          <li>
            <div class="title">Address</div>
            <div id="Address" class="text">
              Suite 305, Griffith Corporate Centre, Kingstown, St. Vincent and the Grenadines
            </div>
          </li>
          <li>
            <div class="title">Email</div>
            <div id="companyEmail" class="text">support@123betnow.net</div>
          </li>
          <li>
            <div class="title">Page Generated On</div>
            <div id="generetedOn" class="text wrap-text">26.04.22 - 12:00:00 GMT +7</div>
          </li>
          <li>
            <div class="title">Registered Website</div>
            <div id="domainName" class="text text-green">123betnow.net</div>
          </li>
          <li>
            <div class="title">Alternate Designation</div>
            <div id="referrerName" class="text text-green">123betnow.net</div>
          </li>
        </ul>
        <a href="#" id="validtyCheck" class="btn text">Status : LICENSED</a>

        <h4>License Verification Terms and Conditions (Disclaimer)</h4>
        <p>
          This License Verification page is provided as a service to verify the present licensing
          status of the Company and Website named above as an IP Provider, who operates under under
          license reference 220341 issued to Saint Vincent eGaming.
        </p>
        <p>
          Creating or maintaining any links from another website to this License Verification page
          without our prior written permission is prohibited.
        </p>
        <p>
          For authenticity, make sure that the address of this website starts with
          “verification.saintvincent-egaming.com”
        </p>
      </div>

      <!-- Images -->
      <div id="contentCertificate" class="content content-type-2">
        <h3 id="certificate">Saint Vincent eGaming<span>License Certificate</span></h3>
        <div class="images-container">
          <div id="myBtn" class="images-content">
            <img
              id="validimage"
              alt=""
              src="~@/assets/images/license.jpg"
              style="border-width: 0px"
            />
          </div>
        </div>
        <!-- The Modal -->
        <div id="myModal" class="modal">
          <!-- Modal content -->
          <div class="modal-content">
            <span class="close">×</span>
            <img id="expandedImg" src="" />
          </div>
        </div>
        <h4>Important License Information</h4>
        <p>
          The trade name “Saint Vincent eGaming” or “SVEG” is owned by Saint Vincent and The
          Grenadines, a public limited company, incorporated on April 15th, 1998 in Saint Vincent.
          SVEG is licensed by the Government of Saint Vincent since Febuary 10th, 2022 under license
          reference 220341 to offer games of chance, including the right to under regulatory
          obligations grant the said offering to third party IP-operators, commonly known as
          “sublicensing”. Public License Certificate “A” is a bespoke, public certificate, listing
          the information of one single domain and its Alternate Designations (“AD’s”) for
          compliance purposes. It is valid for public display within the said domain and its AD’s
          only and cannot be used for any purposes of certification, other than to provide assurance
          to players (“End Users”) that the website is under compliance oversight by SVEG.
        </p>
      </div>
      <!-- Form -->
      <div id="contentComplaints" class="content content-type-2">
        <h3 id="complaints">Complaints</h3>
        <form @submit.prevent="onSendMessage(1)">
          <div class="form-row">
            <div class="input-group form-group col-md-6">
              <label for="name">Name <span class="red-required">*</span></label>
              <input
                name="name"
                type="text"
                id="name"
                class="form-control group"
                v-model="formContact.name"
                required
              />
            </div>
            <div class="input-group form-group col-md-6">
              <label for="email">Email <span class="red-required">*</span></label>
              <input
                name="email"
                type="email"
                id="email"
                class="form-control group"
                v-model="formContact.email"
                required
              />
            </div>
          </div>
          <div class="form-row">
            <div class="input-group form-group col-md-6">
              <label for="website">Website</label>
              <input
                name="website"
                type="text"
                id="website"
                class="form-control group"
                v-model="formContact.website"
              />
            </div>
            <div class="input-group form-group col-md-6">
              <label for="username">Player ID <span class="red-required">*</span></label>
              <input
                name="username"
                type="text"
                id="username"
                class="form-control group"
                v-model="formContact.playerid"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="input-group form-group col-md-12">
              <label for="message">Add Message <span class="red-required">*</span></label>
              <textarea
                name="message"
                id="message"
                rows="4"
                cols="50"
                class="form-control group"
                v-model="formContact.message"
                required
              ></textarea>
            </div>
          </div>
          <div class="form-row">
            <div class="input-group form-group col-md-6">
              <label for="amount">Disputed Amount <span class="red-required">*</span></label>
              <input
                name="amount"
                type="text"
                id="amount"
                class="form-control group"
                v-model="formContact.disputed_amount"
                required
              />
            </div>
            <div class="input-group form-group col-md-6">
              <label for="currency">Currency <span class="red-required">*</span></label>
              <select
                name="currency"
                id="currency"
                class="form-control group"
                v-model="formContact.currency"
                required
              >
                <option
                  :value="currency.Currency_ID"
                  v-for="currency in ListCurrency"
                  :key="currency.Currency_Name"
                >
                  {{ currency.Currency_Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12"></div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <input type="submit" name="btnSubmit" value="Submit" id="btnSubmit" class="btn" />
            </div>
          </div>
        </form>
        <h4>Important Information</h4>
        <p>
          Saint Vincent eGaming ("SVEG") should only be contacted by players when they believe an
          Operator is in breach of their license. All disputes with an Operator about a payout, a
          blocked account, a delay, broken features and so on, should first be taken up directly
          with the Operator. If no resolution is found, you can escalate the matter to Saint Vincent
          eGaming by completing the form above.
        </p>
      </div>

      <!-- Form2 -->
      <div id="contentSelfExclusion" class="content content-type-2">
        <h3 id="selfexclusion">Request For Self-Exclusion</h3>
        <p>
          By using the option to Self-Exclude, you commit to the additional terms as displayed
          below. Please read this information carefully before continuing.
        </p>
        <form action="" @submit.prevent="onSendMessage(2)">
          <div class="form-row">
            <div class="input-group form-group col-md-6">
              <label for="name1">Name</label>
              <input
                name="name1"
                type="text"
                id="name1"
                class="form-control group1"
                v-model="formContact.name"
                required
              />
            </div>
            <div class="input-group form-group col-md-6">
              <label for="email1">Email</label>
              <input
                name="email1"
                type="email"
                id="email1"
                class="form-control group1"
                v-model="formContact.email"
                required
              />
            </div>
          </div>
          <div class="form-row">
            <div class="input-group form-group col-md-6">
              <label for="website1">Website</label>
              <input
                name="website1"
                type="text"
                id="website1"
                class="form-control group1"
                v-model="formContact.website"
              />
            </div>
            <div class="input-group form-group col-md-6">
              <label for="username1">Player ID</label>
              <input
                name="username1"
                type="text"
                id="username1"
                class="form-control group1"
                v-model="formContact.playerid"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="input-group form-group col-md-12">
              <label for="message1">Add Message</label>
              <textarea
                name="message1"
                id="message1"
                rows="4"
                cols="50"
                class="form-control group1"
                v-model="formContact.message"
                required
              ></textarea>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12"></div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <input type="submit" name="btnSubmit1" value="Submit" id="btnSubmit1" class="btn" />
            </div>
          </div>
        </form>
        <h4>Additional Terms For Self-Exclusion</h4>
        <p>
          The option to Self-Exclude ("Self-Exclusion") via the verification page (the: "Seal") is
          an intermediary service of Saint Vincent eGaming ("SVEG"). By submitting a request for
          Self-Exclusion (the "Request") via the Self-Exclusion form (the "Form"), the end user
          ("End-User") triggers a mandatory procedure for the operator (the "Operator") of this
          particular website to discontinue services to the End-User for a minimum of 6 (six) months
          as per the IP-Agreement between SVEG and the Operator. The Request shall not immediately
          trigger Self-Exclusion. SVEG shall first confirm the identity of the End-User. Once the
          request is properly validated, the Operator shall be allowed a grace period of seven
          calendar days to comply with the Request, after which the Operator should confirm the
          Self-Exclusion to the End-User. The End-User is at any time allowed to seek alternate
          means to Self-Exclude. However SVEG does not accept any responsibility for the End-User
          that opts not to follow this particular intermediary procedure.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      formContact: {
        type: null,
        name: null,
        email: null,
        message: null,
        website: null,
        playerid: null,
        disputed_amount: null,
        currency: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      ListCurrency: 'license/ListCurrency',
    }),
  },
  methods: {
    onSendMessage(type) {
      this.formContact.type = type;
      console.log(this.formContact);
      this.$store.dispatch('license/req_postSendContact', this.formContact);
    },
  },
  mounted() {},
  created() {
    this.$store.dispatch('license/req_getListCurrency');
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      // eslint-disable-next-line default-case
      switch (mutation.type) {
        case 'license/SEND_SUCCESS':
          this.formContact = {
            type: null,
            name: null,
            email: null,
            message: null,
            website: null,
            playerid: null,
            disputed_amount: null,
            currency: null,
          };
          break;
      }
    });
  },
};
</script>

<style lang="scss">
.box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.box img {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.red-required {
  color: red !important;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Lora', serif;
  font-size: 14px;
  font-weight: 400;
  color: #2a2a2a;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  line-height: normal;

  background: no-repeat center center fixed #071c4e;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* Firefox 28 and earlier*/
}

a {
  display: block;
  text-decoration: inherit !important;
  color: #2a2a2a;
  -webkit-transition: all 0.4s ease 0.1s;
  -moz-transition: all 0.4s ease 0.1s;
  -o-transition: all 0.4s ease 0.1s;
  transition: all 0.4s ease 0.1s;
}

a:hover {
  color: #f85c70;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-left-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.align-left-right > * {
  padding-right: 30px;
}

.align-flex-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.align-flex-center > * {
  padding-right: 30px;
}

.align-flex-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.align-flex-left-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
}

.wrapper {
  width: 100%;
  padding: 0px 15px;
  margin: 0 auto;
}

@media (min-width: 1220px) {
  .wrapper {
    width: 1220px;
  }
}

.container {
  width: 100%;
  padding: 0px;
}

@media (min-width: 768px) {
  .container {
    padding: 40px 0px;
  }
}

.wrapper {
  width: 100%;
  padding: 0px 0px;
  margin: 0 auto;
}

@media (min-width: 991px) {
  .wrapper {
    width: 730px;
  }
}

.content {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  position: relative;
  overflow: hidden;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.content-type-1 {
  /* background: url(../img/content-1-bg.png) no-repeat center center fixed #fff; */
}

.content-type-1 > * {
  position: relative;
  z-index: 2;
  background-color: transparent;
}

.content-type-1::before {
  content: '';
  display: block;
  background: no-repeat center center #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.content-type-2 {
  /* background: url(../img/content-2-bg.png) no-repeat top center fixed #fff; */
}

.content-type-2 > * {
  /* background: url(../img/content-2-bg.png) no-repeat top center fixed #fff; */
  position: relative;
  z-index: 3;
}

.content-type-2::before {
  content: '';
  display: inline-block;
  width: 102px;
  height: 113px;
  z-index: 2;
  position: absolute;
  top: 20px;
  right: 20px;
}

.content-type-2::after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  background: no-repeat center center #fff;
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media (min-width: 768px) {
  .content {
    padding: 60px 95px;
  }
}

h2 {
  padding-bottom: 35px;
  margin: 0px;
  font-size: 29px;
}

@media (min-width: 768px) {
  h2 {
    /*padding-bottom: 75px;*/
  }
}

.c-table {
  display: table;
  width: 100%;
  padding-bottom: 20px;
}

.c-table > li {
  display: table-row;
}

.c-table > li > div {
  display: table-cell;
  padding: 25px 0;
  border-bottom: 1px solid #eceaec;
}

.c-table > li > div.title {
  padding-right: 10px;
  font-size: 15px;
  font-weight: bold;
  color: #002268;
  width: 200px;
}

.c-table > li > div.text {
  font-size: 15px;
  font-weight: 700;
  color: #141414;
}

.wrap-text {
  white-space: nowrap;
}

.c-table > li > div.text a {
  color: #10d083;
}

h4 {
  padding-top: 30px;
  padding-bottom: 10px;
  margin: 0px;
  font-family: 'Lora', serif;
  font-size: 14px;
  font-weight: 700;
  color: #002268;
  text-align: center;
}

h3 {
  padding-bottom: 20px;
  margin: 0px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #002268;
  font-family: 'Lora', serif;
}

h3 span {
  white-space: nowrap;
}

@media (min-width: 768px) {
  h3 span {
    white-space: inherit;
  }
}

p {
  font-size: 14px;
  color: #595959;
  font-weight: 500;
  text-align: justify;
  /*text-align: center;*/
}

.btn {
  width: 100%;
  height: inherit;
  padding: 20px;
  border: inherit;
  font-size: 18px;
  font-weight: 700;
  background-color: #10d083;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-transition: all 0.4s ease 0.1s;
  -moz-transition: all 0.4s ease 0.1s;
  -o-transition: all 0.4s ease 0.1s;
  transition: all 0.4s ease 0.1s;
}

.btn:hover {
  background-color: #494e54 !important;
  color: #fff !important;
}

@media (min-width: 768px) {
  .btn {
    padding: 20px 100px;
  }
}

.images-container {
  position: relative;
  text-align: center;
}

.images-container #closebtn {
  display: none;
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 2;
  font-size: 40px;
  cursor: pointer;
}

.images-container .images-content {
  display: inline-block;
  max-width: 428px;
}

.images-container .images-content img {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.images-container .big-images-content {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: scroll;
}

form {
  width: 100%;
  overflow: hidden;
}

form .c-row {
  width: 100%;
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
}

form .c-row > div {
  padding: 10px 15px;
}

/* form .c-row > div label {
  width: 100%;
  display: block;
  padding-bottom: 5px;
} */
form .input-group label {
  width: 100%;
  display: block;
  font-weight: bold;
}

form .c-row > div.c-col-6 {
  width: 100%;
}

@media (min-width: 768px) {
  form .c-row > div.c-col-6 {
    width: 50%;
  }
}

form .c-row > div.c-col-12 {
  width: 100%;
}

form input,
form textarea {
  width: 100%;
  height: 40px;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

form textarea {
  height: auto;
}

form .g-recaptcha > div {
  width: 100% !important;
}

form .err-text {
  color: #ff0000;
  font-size: 15px;
  font-weight: 500;
}

form .err-border {
  border-color: #a80000;
}

.form-control {
  width: 100% !important;
}

.success-label,
.fail-label {
  padding: 10px 10px 10px 30px;
  margin: 15px 0;
  border: 1px solid #d6d3d6;
  border-left: 4px solid #10d083;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.fail-label {
  border-left-color: #a80000;
}

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 4;
  /* Sit on top */
  padding-top: 50px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 100%;
}

@media (min-width: 768px) {
  .modal-content {
    width: 60%;
  }
}

.modal-content img {
  width: 100%;
  height: auto;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.btn-err {
  width: 100%;
  padding: 20px;
  border: inherit;
  font-size: 18px;
  font-weight: 700;
  background-color: #ea383d;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-transition: all 0.4s ease 0.1s;
  -moz-transition: all 0.4s ease 0.1s;
  -o-transition: all 0.4s ease 0.1s;
  transition: all 0.4s ease 0.1s;
}

.btn-err:hover {
  color: #fff;
  background-color: #f8494e;
}

.logo-content {
  text-align: center;
}

.images-box img {
  width: 100%;
  max-width: 125px;
}

.images-box svg {
  width: 100%;
  max-width: 125px;
}

.text-2 {
  padding: 30px 0;
}

.text-2 h2 {
  padding: 0px;
  margin: 0px;
  font-family: 'Lora', serif;
  font-size: 26px;
  font-weight: 700;
  color: #002472;

  @media (max-width: 375px) {
    font-size: 22px;
  }
}

.text-2 h2 span {
  display: block;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .text-2 {
    text-align: left;
  }
}

.header-nav {
  position: sticky;
  top: 0px;
  background-color: #fff;

  z-index: 99;
  width: 100%;
  max-width: 730px;
  margin: 0 auto;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #051e52;
}

.header-nav > ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.header-nav > ul > li > a {
  display: block;
  padding: 10px 15px;
  color: #000;

  @media (max-width: 375px) {
    padding: 10px;
  }
}

.header-nav > ul > li > a:hover {
  color: #10d083;
}

.header-nav > ul > li:first-child > a {
  padding-left: 0px;
}

.header-nav > ul > li:last-child > a {
  padding-right: 0px;
}
</style>
